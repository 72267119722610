import {BasicsClass} from "@/common/BasicsClass";

export default class CardFeeAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/shopMember/memberSpend',
            name: 'list',
            label: '列表'
        }],
        ['export', {
            url: '/shopMember/memberSpendExport',
            name: 'export',
            label: '导出'
        }],

    ])

    //单店卡消费记录
    public card_fee(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    //单店卡消费记录--导出
    public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('export').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
