

























































































import {Component, Vue} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import CardFeeAPI from "@/common/api/cardFee";
export const pageApiList = getPageAPIs(CardFeeAPI);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new CardFeeAPI();

  private form: any = {
    phone: "",
    order_no: "",
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {

    const form = this.form
    if (Array.isArray(form.time) && form.time.length === 2) {
      form.spend_start_time = Util.dateTimeUtilnohms(form.time[0]);
      form.spend_end_time = Util.dateTimeUtilnohms(form.time[1]);
    } else {
      form.spend_start_time = undefined
      form.spend_end_time = undefined
    }
    console.log('表单参数', form)
    this.buyAddressApi.card_fee(
        {
          order_no: form.order_no,
          phone: form.phone,
          spend_start_time: form.spend_start_time,
          spend_end_time: form.spend_end_time,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          console.log('单点卡消费记录表格', res)
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    phone: "手机号",
    user_name: "用户名",
    order_no: "订单编号",
    shop_name: "店铺名称",
    price: "费用",
    spend_type: "消费类型",
    settle_type: "结算类型",
    before_price: "消费前余额",
    after_price: "消费后余额",
    spend_time: "消费时间",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "phone",
    "user_name",
    "order_no",
    "shop_name",
    "price",
    "spend_type",
    "settle_type",
    "before_price",
    "after_price",
    "spend_time",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const {order_no,phone,time} = form;
    const params: any = {};
    if(order_no){
      params.order_no=order_no
    }
    if(phone){
      params.phone=phone
    }
    if (Array.isArray(time) && time.length === 2) {
      params.spend_start_time = this.form.time[0]
      params.spend_end_time = this.form.time[1]
    } else {
      this.$message.error("请选择时段");
      return;
    }
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.buyAddressApi.exportList(params,
        (res: any) => {
          const spend_start_time: string = Util.dateUtil(this.form.time[0]);
          const spend_end_time: string = Util.dateUtil(this.form.time[1]);
          const filename = `单店卡消费记录 ${spend_start_time}到${spend_end_time}.xlsx`;
          // const { list: originList } = res.data || {};
          for (const item of res.data.list) {
            item.spend_type = item.spend_type == 1 ? '台球' : (item.spend_type == 2 ? '棋牌' : (item.spend_type == 3 ? '后台' : item.spend_type))
            item.settle_type = item.settle_type == 1 ? '自动结算' : (item.settle_type == 2 ? '收银台' : (item.settle_type == 3 ? '后台' : item.settle_type))
          }
          const originList = res.data.list || [];

          // const {
          //   list: originList
          // } = data || {}

          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                // const { orderNum } = item || {};
                const newItem = {
                  ...(item || {}),
                };

                for (let key in item) {
                  if (typeof item[key] == "number") {
                    newItem[key] = item[key].toString();
                  }
                }

                // newItem.orderNum =
                //   typeof orderNum == "number"
                //     ? orderNum.toString()
                //     : orderNum || "";

                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          // const testData = [
          //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
          // ]

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  /** end 导出excel */
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
